#main.multi {
  .error {
    margin-bottom: 0;
  }
}

.invalid_marker,
.valid_marker {
  position: absolute;
  right: 5px;
  .device-mobile & {
    font-size: 24px;
  }
}

.invalid_marker {
  color: red;
}

.valid_marker {
  color: green;
}

input[type='text'],
textarea {
  border: 1px solid #e6e6e6;
}
