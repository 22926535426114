/* power reviews section */
#pr-review-display {
  .p-w-r {
    .pr-review-display {
      .pr-multiselect {
        .pr-multiselect-button {
          padding: 0 16px 0 0;
          margin: 5px 10px 0 0;
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'JA ';
                visibility: visible;
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'NEJ ';
                visibility: visible;
              }
            }
          }
          .pr-rd-flag-review-container {
            clear: both;
            a {
              padding-left: 0;
            }
          }
        }
      }
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          &::before {
            content: 'Filtrere efter:';
          }
        }
        .pr-rd-review-header-sorts {
          width: 100%;
          .pr-rd-sort-group {
            &::before {
              content: 'Sorter efter: ';
            }
          }
        }
        .pr-rd-sort {
          background: url('/media/images/stars/down-arrow.png') no-repeat scroll 100% 0 transparent;
        }
      }
    }
  }
}

.write_a_review__container {
  .pr-cb-tag-container {
    .pr-label-control {
      span {
        white-space: pre-wrap;
      }
    }
  }
}

.mpp-grid {
  .reviews {
    .p-w-r {
      .pr-snippet {
        .pr-category-snippet__rating,
        .pr-category-snippet__total,
        .pr-snippet-stars-container,
        .pr-snippet-read-and-write {
          display: block;
        }
        .pr-snippet-stars-container {
          float: none;
        }
      }
    }
  }
}
