.spp_view {
  .spp-product {
    .product-info {
      .ppu {
        margin-left: 15px !important;
        font-size: 15px;
      }
    }
    .shaded {
      .replenishment {
        display: none;
      }
    }
  }
  .spp_product_status {
    .coming_soon_stock_msg {
      a.email-me {
        color: #5dba98;
      }
    }
  }
}

.device-mobile {
  .field-mobile-template {
    .site-logo-responsive-v1 {
      padding-left: 120px;
    }
  }
}

.device-mobile {
  #top {
    #header {
      .block-template-site-logo-responsive-v1 {
        width: auto;
      }
    }
  }
}

.accordionItem.three-step-shopbystep-mobile-v1 {
  h3.product-name {
    min-height: 100px;
  }
}

.device-mobile {
  .customer-care-list {
    li.customer-care-list-item.chat,
    li.customer-care-list-item.sms {
      display: none;
    }
  }
  .popup-offer-v1 {
    .outer-wrap {
      height: auto;
      &.signup {
        background: none repeat scroll 0 0 $color-white;
        border: 2px solid $color-black;
        height: 353px;
        overflow: hidden;
        padding: 20px 20px 15px;
        position: fixed;
        top: 20%;
        z-index: 10000;
      }
    }
    .popup-offer-close {
      background: transparent url(/sites/clinique/themes/cl_base/img/icon-close.png) no-repeat scroll 0 0/15px 15px;
      cursor: pointer;
      height: 15px;
      position: absolute;
      right: 5px;
      text-align: left;
      top: 5px;
      width: 15px;
    }
  }
}

.popup-offer-msg {
  #welcome-popover-checkbox {
    vertical-align: top;
    margin-top: 10px;
  }
  .popup-offer-msg-text {
    p {
      width: 90%;
      margin-left: 5px;
      display: inline-block;
    }
  }
}

body#viewcart,
body#samples,
body#shipping,
body#billing,
body#review,
body#confirm {
  .footer-top-mobile-v1 {
    .newsletter-signup {
      display: block;
      visibility: visible;
    }
  }
}

.accordionItem {
  h3 span {
    &.heading-text {
      position: relative;
      right: 20px;
    }
  }
}

.info-msg {
  padding: 14px;
}

#landing {
  &.device-mobile {
    #sign-up-cl-emails {
      .newsletter-signup {
        display: block;
      }
    }
  }
}
/* CL Randomizer Game */
section.clinique-spinner section#game-win-box {
  .win-box-btns {
    .right-btn-box,
    .single-btn-box {
      .button {
        @include breakpoint($small-max) {
          @include swap_direction(padding, 5px 10px);
        }
      }
    }
  }
}

@include breakpoint($small-max) {
  section {
    &.clinique-spinner section#game-win-box {
      .win-image,
      .win-image--mobile {
        height: 200px;
      }
    }
  }
}

section.clinique-spinner {
  .game-spinner {
    @include breakpoint($medium-max) {
      @include swap_direction(margin, 0 7px);
    }
    @include breakpoint($small-max) {
      @include swap_direction(margin, 0 3px);
    }
  }
}

.random-gift-picker-formatter {
  .pop-up-box {
    @include breakpoint($medium-max) {
      max-height: 684px;
    }
    @include breakpoint($xlarge-max) {
      max-height: 684px;
    }
  }
}

.clinical-reality__modules--recommended-products {
  .clinical-reality {
    &__recommended-products--carousel {
      top: 39%;
    }
  }
}

.clinicalreality-container {
  .clinical-reality {
    &__intro {
      &--iframe-mobile {
        position: fixed;
        height: 85%;
        bottom: 0;
      }
    }
  }
}
