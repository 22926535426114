.pc-hidden {
  display: block;
  @media #{$medium-up} {
    display: none;
  }
}

.mobile-hidden {
  display: none;
  @media #{$medium-up} {
    display: block;
  }
}

.sticky-add-to-bag {
  position: fixed;
  top: 0;
  @if $cr19 == true {
    // bottom of page for redesign
    top: auto;
    bottom: 0;
  }
  right: 0;
  width: 100%;
  z-index: 9999;
  background: $color-white;
  box-shadow: 0 1px 5px $color-dark-grey;
  .sticky-add-to-bag--inner {
    max-width: 1040px;
    margin: 0 auto;
    @if $cr19 == true {
      @include container-max-width-x-large;
      @include container-padding-rules;
      padding: 20px 10px;
      .logo {
        display: none;
      }
      .price,
      .product-full__name {
        @include text-title--small;
      }
    }
  }
  .sticky-add-to-bag__image-single,
  .site-logo {
    display: inline-block;
    vertical-align: middle;
  }
  .site-logo {
    margin: 8px 20px 0 0;
    max-width: 30%;
    @if $cr19 == true {
      display: none;
    }
  }
  .sticky-add-to-bag__image-single img {
    height: 50px;
  }
  .product-shade-picker {
    .product-property {
      display: none;
    }
    position: relative;
    padding-right: 10px;
    &__show-all-shades,
    &__show-all-shades-panel {
      display: none;
    }
  }
  .sticky-add-to-bag__description__container {
    display: inline-block;
    vertical-align: middle;
    max-width: 420px;
    @if $cr19 == true {
      max-width: none;
    }
    .sticky-add-to-bag__description {
      display: inline-block;
      vertical-align: middle;
      max-width: 50%;
      @if $cr19 == true {
        max-width: 80%;
        margin-#{$ldirection}: 20px;
      }
    }
  }
  .sticky-add-to-bag__section {
    display: inline-block;
    vertical-align: middle;
    float: right;
    padding: 10px 0 0 0;
    @if $cr19 == true {
      padding: 0;
    }
    height: 50px;
    .product-size,
    .order_arrival,
    .product-shade-picker,
    .product-full__price,
    .spp_product_status,
    .product-full__add-to-bag,
    .shopping-bag {
      display: inline-block;
      vertical-align: middle;
      .product-size {
        display: none;
      }
      .price {
        position: relative;
        border: 0;
        padding: 0;
        @if $cr19 == true {
          @include text-title--med;
          color: $color-black;
          font-size: 17px;
          font-weight: normal;
          padding: 0;
          margin-#{$rdirection}: 16px;
          @media #{$cr19-xlarge-up} {
            font-size: 28px;
            line-height: get-line-height(28px, 32px);
          }
        }
      }
      .btn-add-to-bag {
        background: $color-black;
        font-size: 11px;
        padding: 0 40px;
        letter-spacing: 1.7px;
        margin-right: 25px;
        &.hidden {
          display: none;
        }
        @if $cr19 == true {
          @include elc-button;
          height: 56px;
          line-height: 56px;
          @media #{$cr19-medium-up} {
            height: 64px;
            line-height: 64px;
          }
          &.add-to-bag {
            width: 100%;
          }
        }
      }
      .gnav-drop-down-v1 {
        height: auto;
      }
    }
    .product-size {
      .sbHolder {
        width: 110px;
      }
    }
    .shopping-bag {
      top: -7px;
      position: relative;
      @if $cr19 == true {
        display: none; // cart icon is in gnav only for redesign
      }
    }
    .product-full__tax-label {
      display: inline-block;
      font-size: 20px;
    }
    .product-full__price {
      padding: 0 15px;
      .strike-thru-price {
        display: inline-block;
        font-size: 14px;
      }
      .sale-price {
        color: $color-red;
      }
    }
  }
  .spp_product_status {
    padding: 8px;
    width: 100%;
    text-align: center;
  }
  .sbOptions {
    @if $cr19 == true {
      margin-top: -273px; // account for loading on bottom of page, position plus max height
      top: auto;
      bottom: 23px;
    }
  }
}

.spp__container {
  @if $cr19 == true {
    @include container-max-width-x-large;
    @include container-padding-rules;
  }
  .product-full,
  .product-full__video-container,
  .product-full__cross-sell {
    max-width: 1008px;
    margin: 0 auto;
    @if $cr19 == true {
      max-width: none;
    }
  }
  font-size: 14px;
  h2 {
    font-size: 22px;
    @media #{$medium-up} {
      font-size: 36px;
    }
  }
  .sbToggle {
    right: 5px;
    background: url('/media/images/icons/select_arrows.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 5px;
    height: 10px;
    opacity: 1;
    top: 6px;
  }
  //placed outside .product-full to cover both sticky add to bag and product-full.
  .product-swatch-selector {
    .sbHolder {
      width: 150px;
      padding-left: 25px;
      @media #{$medium-up} {
        border: solid 1px $color-lighter-grey;
        height: 25px;
      }
    }
    .sbSelector {
      font-size: 14px;
    }
    .sbToggle {
      right: 5px;
    }
    .sbOptions {
      a {
        margin-left: 25px;
      }
    }
  }
  .product-full {
    padding-top: 10px;
    @media #{$medium-up} {
      padding-top: 40px;
    }
    .selectbox-wrapper {
      display: inline-block;
      float: none;
      padding: 0;
      background-image: url('/media/images/icons/select_arrows.png');
      background-repeat: no-repeat;
      background-position: right 6px center;
      width: 100%;
      @media #{$medium-up} {
        width: auto;
        min-width: 125px;
        padding: 0;
        display: block;
        max-width: 160px;
        background: none;
      }
      .selectbox {
        border: none;
        background: none;
        font-size: 14px;
        border: solid 1px $color-light-grey;
        border-radius: 0;
        padding: 5px 10px;
        width: 100%;
        @media #{$medium-up} {
          width: auto;
          min-width: 130px;
        }
      }
    }
    .sbHolder {
      border: solid 1px $color-light-grey;
      background: none;
      float: none;
      .sbSelector {
        margin: 0;
        text-indent: 5px;
        font-size: 14px;
      }
      .sbOptions {
        li {
          padding: 0;
        }
        a {
          padding: 4px 0 4px 5px;
        }
      }
    }
    .product-full__header {
      @media #{$medium-up} {
        float: right;
        width: 375px;
        @if $cr19 == true {
          width: 50%;
        }
      }
      h1.product-full__name {
        color: $color-black;
        padding: 0 30px;
        font-size: 20px;
        line-height: 1.2em;
        width: 375px;
        @media #{$medium-up} {
          font-size: 26px;
          padding: 0 0 10px 0;
          margin: 0;
          @if $cr19 == true {
            font-size: 22px;
          }
        }
        @if $cr19 == true {
          @include text-title--med;
          color: $color-black;
          width: auto;
          height: auto;
          margin: 0;
          padding: 0;
          min-height: auto;
          @media #{$cr19-xlarge-up} {
            font-size: 28px;
            line-height: get-line-height(28px, 32px);
          }
        }
      }
      .breadcrumbs {
        padding: 0 0 30px 0;
        @if $cr19 == true {
          @media #{$cr19-medium-up} {
            padding-bottom: 15px;
          }
        }
        .breadcrumbs__item {
          display: inline-block;
          &:first-child {
            padding-right: 10px;
            @if $cr19 == true {
              padding: 0;
            }
          }
          &:last-child {
            padding-left: 10px;
            @if $cr19 == true {
              padding: 0;
            }
          }
          a {
            color: $color-black;
            @if $cr19 == true {
              color: $cr19-text-grey;
              font-family: $base-font-family-roman;
              font-size: 15px;
              letter-spacing: 0.02em;
              line-height: get-line-height(15px, 20px);
              @media #{$cr19-medium-up} {
                font-size: 17px;
                line-height: get-line-height(17px, 24px);
              }
            }
          }
        }
        .breadcrumbs__slash {
          color: $color-dark-grey;
          @if $cr19 == true {
            padding: 10px;
          }
        }
      }
    }
    .product-full__media {
      display: block;
      vertical-align: top;
      display: inline-block;
      @media #{$medium-up} {
        width: 48%;
        display: inline-block;
        float: left;
      }
      @if $cr19 == true {
        display: block;
        @media #{$cr19-medium-up} {
          display: inline-block;
        }
      }
      .product-full__image {
        display: inline-block;
        vertical-align: top;
        margin: 0 auto;
        position: relative;
        max-width: 402px;
        @if $cr19 == true {
          display: block;
          @media #{$cr19-medium-up} {
            display: inline-block;
            max-width: 100%;
          }
        }
        &-wrapper {
          display: inline-block;
          position: relative;
          vertical-align: top;
          @if $cr19 == true {
            display: block;
            @media #{$cr19-medium-up} {
              display: inline-block;
              width: 75%;
            }
          }
        }
        &--slide,
        &--slide img {
          width: 402px;
          height: 464px;
          @media #{$medium-up} {
            @media #{$portrait} {
              width: 290px;
              height: 334px;
            }
          }
          @if $cr19 == true {
            width: 335px;
            height: 386px;
            @media #{$cr19-medium-up} {
              width: 100%;
              height: auto;
              max-width: 100%;
            }
          }
        }
        &--slide {
          @media #{$medium-up} {
            display: none;
          }
          &.active {
            @media #{$medium-up} {
              display: block;
            }
          }
        }
        &--multi-smoosh {
          display: inline-block;
          float: left;
          width: 185px;
          height: 213px;
          @media #{$medium-up} {
            width: 201px;
            height: 232px;
            @media #{$portrait} {
              width: 144px;
              height: 167px;
            }
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        &--text-alt-image {
          background-color: $color-light-white;
          &__title {
            font-family: HelveticaNeueLTStd75Bold;
            text-transform: uppercase;
            font-size: 14px;
            padding: 20px 20px 10px 20px;
            width: 340px;
          }
          &__body {
            font-family: HelveticaNeueLTStd35Thin;
            font-size: 34px;
            width: 340px;
            padding: 0 20px;
          }
        }
        &__video {
          position: relative;
          &.slick-slide {
            img.mobile-hidden {
              display: none;
            }
          }
          &-overlay {
            background-image: url('/media/images/icons/video_play.png');
            background-repeat: no-repeat;
            background-position: top right;
            padding: 20px;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
          }
          .poster-frame {
            width: 100%;
          }
        }
        &.slick-slider {
          touch-action: auto;
        }
      }
      .product-full__alt-images-slider {
        display: none;
        margin: 60px 20px 0 0;
        width: 50px;
        @media #{$medium-up} {
          display: none;
        }
        &.active {
          @media #{$medium-up} {
            display: inline-block;
          }
        }
        .slick-slide {
          border: none;
        }
        .slick-arrow {
          height: 6px;
          width: 11px;
          left: 20px;
          position: relative;
          z-index: 100;
          padding: auto;
        }
        .slick-prev {
          top: -25px;
          background: url('/media/images/icons/spp_thumb_carousel_caret_up.png') no-repeat;
          &:before {
            content: none;
          }
        }
        .slick-next {
          bottom: -25px;
          background: url('/media/images/icons/spp_thumb_carousel_caret_down.png') no-repeat;
          &:before {
            content: none;
          }
        }
      }
      .slick-dots {
        position: relative;
        bottom: 0;
        li {
          width: 10px;
        }
      }
      .product-full__alt-images-slider-button {
        height: 6px;
        width: 11px;
        margin: 0 auto;
        cursor: pointer;
      }
      .product-full__alt-images {
        display: inline-block;
        vertical-align: top;
        position: relative;
        &-thumb {
          padding: 5px 0;
          cursor: pointer;
          border-bottom: 3px solid $color-white;
          &.active {
            border-color: $color-black;
          }
          img {
            width: 50px;
          }
          .thumb-multi-smoosh {
            display: inline-block;
            float: left;
            img {
              width: 25px;
            }
          }
        }
      }
      .badge {
        position: absolute;
        top: 50px;
        #{$ldirection}: 50px;
        display: table;
        width: 65px;
        height: 65px;
        text-align: center;
        @include border-radius(50%);
        color: #fff;
        z-index: 1;
        padding: 0;
        line-height: 14px;
        font-family: $base-font-family;
        vertical-align: middle;
        margin: 0;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
        &--large {
          top: 0;
          left: 0;
        }
        &.badge_1,
        &.badge_2,
        &.badge_3,
        &.badge_4,
        &.badge_5,
        &.badge_17,
        &.badge_92,
        &.badge_94,
        &.badge_96,
        &.badge_97 {
          background: $color-cl-grey;
        }
        &.badge_96 {
          font-size: 12px;
        }
        &.badge_30 {
          background: $color-cl-grey;
        }
        html.ie7 & {
          text-align: center;
        }
        .badge-inner {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          html.ie7 & {
            position: relative;
            margin-top: inherit;
            *clear: expression(
              style.marginTop = '' +
                (
                  offsetHeight < parentNode.offsetHeight ? parseInt((parentNode.offsetHeight - offsetHeight) / 2) + 'px':
                    '0'
                ),
              style.clear = 'none',
              0
            );
          }
        }
      }
    }
    .product-full__share {
      text-align: center;
      padding-top: 20px;
      @media #{$medium-up} {
        padding-top: 0;
      }
      @if $cr19 == true {
        display: block;
        padding-top: 0;
      }
      .product-full__share-button {
        cursor: pointer;
        height: 23px;
        display: inline-block;
        &-wrapper {
          @media #{$medium-up} {
            margin-top: 20px;
          }
        }
      }
      .product-full__share-button--share {
        background: url('/media/images/icons/share-icon.png');
        width: 15px;
        margin-right: 20px;
        position: relative;
        &:hover .product-full__share-overlay {
          display: block;
          text-align: center;
          padding-top: 15px;
          .share-icon {
            display: inline-block;
            cursor: pointer;
            height: 18px;
            margin-right: 15px;
            &:last-child {
              margin: 0;
            }
          }
          .product-full__share__facebook {
            background: url('/media/images/icons/share_icon_facebook.png') no-repeat;
            width: 14px;
          }
          .product-full__share__twitter {
            background: url('/media/images/icons/share_icon_twitter.png') no-repeat;
            width: 20px;
          }
          .product-full__share__pinterest {
            background: url('/media/images/icons/share_icon_pinterest.png') no-repeat;
            width: 14px;
          }
        }
      }
      .product-full__share-button--favorite {
        background: url('/media/images/icons/favorite-icon.png');
        width: 18px;
        &.active {
          background: url('/media/images/icons/favorite-icon-active.png');
        }
      }
      .icon-heart-message {
        border: 1px solid #000000;
        display: none;
        margin: 10px auto 0;
        padding: 5px;
        text-transform: uppercase;
        text-align: center;
        width: 345px;
        &.active {
          display: block;
        }
        .favorites-added-signedOut,
        .favorites-added-signedIn,
        .favorites-exists {
          display: none;
        }
        &.favorites-add-success .favorites-added-signedIn {
          display: none;
        }
        &.favorites-add-success .favorites-added-signedOut {
          display: block;
        }
        &.favorites-add-success .favorites-exists {
          display: none;
        }
        &.favorites-add-exists .favorites-exists {
          display: block;
        }
      }
      .product-full__share-overlay {
        width: 146px;
        height: 57px;
        position: absolute;
        background: url('/media/images/produkter/share_background.png') no-repeat;
        top: -56px;
        left: -66px;
        display: none;
      }
    }
    .product-full_review-mobile {
      padding: 0 15px;
    }
    .BVRRRatingSummary {
      text-align: left;
    }
    .BVRRRatingSummaryLinks {
      display: none;
    }
    #pr-spp-snippet .p-w-r .pr-snippet {
      text-align: left;
      padding-top: 10px;
      @media #{$medium-up} {
        padding: 0 0 25px 0;
        height: 1em;
        margin: 0;
      }
      .pr-snippet-read-and-write {
        @media #{$medium-up} {
          margin: 5px 0 0 0;
        }
      }
      .pr-snippet-stars-container {
        display: inline-block;
        @media #{$medium-up} {
          vertical-align: top;
        }
      }
    }
    .product-full__content {
      display: block;
      @media #{$medium-up} {
        display: inline-block;
        float: right;
        width: 375px;
        padding-bottom: 20px;
        @if $cr19 == true {
          width: 50%;
        }
      }
      .product-full__benefits,
      .product-full__skin-types {
        font-family: HelveticaNeueLTStd45Light;
        &-name {
          font-family: HelveticaNeueLTStd75Bold;
        }
      }
      .product-full__benefits {
        padding: 0 0 0 20px;
        @media #{$medium-up} {
          padding: 0 0 0 0;
        }
      }
      .product-full__skin-types {
        padding: 0 0 0 20px;
        @media #{$medium-up} {
          padding: 0;
        }
      }
      .product-full__assets {
        .product-full__asset:last-child {
          @media #{$medium-up} {
            padding-bottom: 10px;
          }
        }
      }
      @if $cr19 == true {
        @include text-body-text--no-xl;
        .sells-with--title strong {
          @include text-title--small;
          @media #{$cr19-xlarge-up} {
            font-size: 17px;
            line-height: get-line-height(17px, 24px);
          }
        }
        .sells-with--description--product-name {
          color: $color-black;
        }
      }
      .product-full__description {
        font-size: 15px;
        color: $color-black;
        line-height: 1.3em;
        @media #{$medium-up} {
          padding: 0 0 10px 0;
        }
        &--short {
          margin: 0;
        }
        .product-full__description--more-btn,
        .product-full__description--less-btn {
          text-decoration: underline;
          cursor: pointer;
        }
        @if $cr19 == true {
          @include text-body-text;
          font-style: normal;
          color: $color-black;
          text-align: #{$ldirection};
          display: inline-block;
          @media #{$cr19-xlarge-up} {
            font-size: 17px;
            line-height: get-line-height(17px, 24px);
          }
        }
      }
      .product-shade-picker {
        .product-swatch-selector {
          padding: 10px 20px;
          @media #{$medium-up} {
            padding: 0;
          }
          background-position: right 26px center;
        }
        .sku_select.swatch {
          @media #{$medium-up} {
            padding-left: 20px;
          }
        }
        &__shades {
          margin-top: 15px;
          .slides {
            display: flex;
            flex-wrap: wrap;
          }
          .product-shade-picker__shade-inner {
            width: 80px;
            height: 80px;
            margin: 2px;
            cursor: pointer;
            display: flex;
            @media #{$medium-up} {
              width: 30px;
              height: 30px;
            }
            &-color {
              width: 80px;
              height: 80px;
              @media #{$medium-up} {
                height: 30px;
                width: 30px;
              }
            }
            &.active {
              border-bottom: 3px solid $color-black;
            }
          }
        }
        .product-property-list {
          padding: 5px 0 10px 20px;
          width: 100%;
          @media #{$medium-up} {
            padding: 0 0 10px 0;
          }
        }
        .product-property {
          font-family: HelveticaNeueLTStd45Light;
          display: block;
          @media #{$medium-up} {
            display: inline-block;
            width: 50%;
            float: left;
          }
          &__name {
            font-family: HelveticaNeueLTStd75Bold;
          }
          &.hidden {
            display: none;
          }
        }
        &__show-all-shades {
          float: right;
          text-transform: uppercase;
          background-image: url('/media/images/icons/plus-icon.png');
          background-repeat: no-repeat;
          background-position: right center;
          background-size: 12px;
          padding-right: 20px;
          margin-right: 10px;
          font-weight: bold;
          &.open {
            background-image: url('/media/images/icons/minus-icon.png');
          }
          @media #{$medium-up} {
            display: none;
          }
          &-panel {
            z-index: 200;
            position: relative;
            background: $color-white;
            height: 0;
            overflow: hidden;
            transition-property: height;
            transition-duration: 1s;
            width: 100%;
            @media #{$medium-up} {
              display: none;
            }
            &.open {
              height: auto;
              padding: 20px 5px 5px 5px;
              transition-property: height;
              transition-duration: 1s;
            }
          }
          &__inner {
            display: inline-block;
            width: 24%;
            vertical-align: top;
            padding-bottom: 25px;
            cursor: pointer;
          }
          &__shade {
            width: 80px;
            height: 80px;
            display: flex;
          }
          &__color {
            width: 80px;
            height: 80px;
          }
          &__title {
            line-height: 1.1em;
            padding: 6px 0;
            text-align: center;
            width: 80px;
            height: 3.5em;
            font-size: 12px;
            border-bottom: 5px solid $color-white;
          }
          &__inner.active .product-shade-picker__show-all-shades__title {
            border-bottom-color: $color-black;
          }
        }
        .product-size {
          font-family: HelveticaNeueLTStd45Light;
          float: right;
          padding-top: 5px;
          font-size: 14px;
        }
      }
      .product-size {
        font-family: HelveticaNeueLTStd45Light;
        font-size: 14px;
        padding: 16px 20px 10px 20px;
        @if $cr19 == true {
          padding: 0;
        }
        width: 100%;
        display: block;
        @media #{$medium-up} {
          display: inline-block;
          float: left;
          padding: 0 0 10px 0;
          width: 48%;
        }
        &.pc-hidden {
          @media #{$medium-up} {
            display: none;
          }
        }
        &.mobile-hidden {
          display: none;
          @media #{$medium-up} {
            font-size: 15px;
            padding: 0 0 10px 5px;
            float: none;
            display: block;
          }
        }
      }
      .product-size__non-shaded {
        font-size: 11px;
        padding: 0 0 25px 5px;
      }
      .product-full__autoreplenish--mobile {
        padding: 15px 20px 15px;
        display: block;
        @media #{$medium-up} {
          display: none;
        }
        &.hidden {
          display: none;
        }
        @if $cr19 == true {
          display: none;
        }
      }
    }
    .product-full__footer {
      padding: 0 20px;
      @media #{$medium-up} {
        padding: 0;
      }
      @if $cr19 == true {
        padding: 0;
        .product-full__share {
          display: none;
        }
        .product-full__price,
        .product-full__add-to-bag {
          width: 50%;
          padding: 0;
          margin: 0;
        }
      }
      .product-full__autoreplenish {
        float: left;
        clear: left;
        padding-top: 15px;
        &.hidden {
          display: none;
        }
      }
      .product-full__cta {
        overflow: visible;
        @media #{$medium-up} {
          padding-top: 5px;
        }
        @if $cr19 == true {
          display: flex;
          align-items: center;
          width: 100%;
          margin-top: 25px;
          padding-top: 5px;
          border-top: 1px solid $cr19-border-light;
          @media #{$cr19-medium-up} {
            padding-top: 25px;
          }
        }
        .price {
          float: left;
          font-family: HelveticaNeueLTStd75Bold;
          font-size: 14px;
          margin: 0;
          border: none;
          padding-top: 0;
          @media #{$medium-up} {
            padding: 0 0 0 5px;
            @if $cr19 == true {
              padding: 0;
            }
          }
          @if $cr19 == true {
            @include text-title--med;
            color: $color-black;
            font-size: 17px;
            font-weight: normal;
            padding: 0;
            margin-#{$rdirection}: 16px;
            @media #{$cr19-xlarge-up} {
              font-size: 28px;
              line-height: get-line-height(28px, 32px);
            }
          }
        }
        .product-full__tax-label {
          font-size: 20px;
          width: 19%;
          @media #{$cr19-xlarge-up} {
            width: 11%;
          }
        }
        .product-full__price {
          .strike-thru-price {
            display: inline-block;
            font-size: 14px;
          }
          .sale-price {
            color: $color-red;
          }
          @if $cr19 == true {
            @media #{$cr19-large-up} {
              width: 50%;
              float: #{$ldirection};
            }
          }
        }
        .product-full__add-to-bag {
          clear: both;
          padding-top: 10px;
          @media #{$medium-up} {
            padding-top: 55px;
            @if $cr19 == true {
              padding-top: 0;
            }
          }
          @if $cr19 == true {
            @media #{$cr19-large-up} {
              width: 50%;
              float: #{$ldirection};
            }
          }
          .order_arrival {
            padding-top: 10px;
            @media #{$medium-up} {
              display: block;
            }
          }
          .btn-primary {
            width: 100%;
            font-size: 12px;
            height: 37px;
            line-height: 41px;
            padding: 0;
          }
          .btn-add-to-bag {
            background: $color-black;
            letter-spacing: 2px;
            &.hidden {
              display: none;
            }
            @if $cr19 == true {
              @include elc-button;
              height: 56px;
              line-height: 56px;
              @media #{$cr19-medium-up} {
                height: 64px;
                line-height: 64px;
              }
              &.add-to-bag {
                width: 100%;
              }
            }
          }
          .pre-order-btn {
            color: $color-black;
            background: $color-white;
            border: 1px solid $color-black;
          }
          .pre-order-no-shop {
            background: $color-btn-gray;
          }
          .coming_soon_stock_msg {
            .email-me {
              display: block;
              margin-top: 10px;
            }
          }
          .temp_out_of_stock_msg {
            margin-top: 10px;
          }
        }
      }
    }
    .product-full__qty-add {
      font-family: HelveticaNeueLTStd45Light;
      float: right;
      display: inline-block;
      padding: 0 8px 0 0;
      @media #{$medium-up} {
        padding: 0;
      }
      .btn-qty {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-size: contain;
        cursor: pointer;
      }
      .btn-qty__label {
        display: inline-block;
        padding-right: 5px;
      }
      .btn-qty__subtract {
        background-image: url('/media/images/icons/minus-icon.png');
      }
      .btn-qty__add {
        background-image: url('/media/images/icons/plus-icon.png');
      }
      .btn-qty__value {
        width: 20px;
        text-align: center;
        display: inline-block;
      }
    }
  }
  .product-full__accordion {
    @if $cr19 == true {
      @media #{$cr19-xlarge-up} {
        &-use {
          margin-top: 60px;
          padding-top: 50px;
          border-top: 1px solid $cr19-border-light;
        }
      }
    }
    &--details {
      background: $color-c1-white;
    }
    &__container {
      padding: 15px 0;
    }
    &-body {
      margin: 0 auto;
      @media #{$medium-up} {
        max-width: 1004px;
        min-height: 450px;
        background-size: cover;
      }
      @if $cr19 == true {
        position: relative;
        @media #{$cr19-medium-up} {
          max-width: none;
          min-height: auto;
        }
      }
      ul {
        margin-left: 20px;
        li {
          list-style: disc;
        }
      }
      &.text-left {
        .product-full__accordion__panel {
          @media #{$medium-up} {
            float: left;
            padding: 80px 0 0 80px;
            @if $cr19 == true {
              padding: 15px 0 25px;
            }
          }
          @if $cr19 == true {
            border-top: 1px solid $cr19-border-light;
            padding: 0;
            @media #{$cr19-medium-up} {
              margin-top: 60px;
            }
          }
        }
        .product-full__accordion-image {
          @media #{$medium-up} {
            float: right;
            padding: 80px 80px 0 0;
          }
        }
      }
      &.text-right {
        .product-full__accordion__panel {
          @media #{$medium-up} {
            float: right;
            padding: 80px 80px 0 0;
          }
          @if $cr19 == true {
            @media #{$cr19-medium-up} {
              padding: 0;
            }
          }
        }
        .product-full__accordion-image {
          @media #{$medium-up} {
            float: left;
            padding: 80px 0 0 80px;
          }
          @if $cr19 == true {
            @media #{$cr19-medium-up} {
              padding: 0;
            }
          }
        }
      }
    }
    &-background {
      @media #{$medium-up} {
        max-width: 1008px;
        position: absolute;
        width: 100%;
        height: 450px;
        background-repeat: no-repeat;
        background-size: cover;
      }
      @if $cr19 == true {
        @media #{$cr19-medium-up} {
          padding: 0;
          #{$rdirection}: $cr19-pad-large;
          top: 60px;
        }
      }
    }
    .product-full__accordion-image {
      display: none;
      @media #{$medium-up} {
        display: block;
      }
      img {
        max-width: 350px;
        @if $cr19 == true {
          max-width: none;
        }
      }
      @if $cr19 == true {
        @media #{$cr19-xlarge-up} {
          max-width: none;
        }
      }
    }
    .product-full__accordion__panel {
      border-top: 1px solid $color-lighter-grey;
      @if $cr19 == true {
        border-top: 1px solid $cr19-border-light;
      }
      font-family: $base-font-family;
      @media #{$medium-up} {
        padding: 0;
        border: none;
        width: 46%;
        position: relative;
      }
      @if $cr19 == true {
        padding: 0;
      }
      a {
        color: black;
        text-decoration: underline;
      }
      .product-full__accordion__title {
        font-size: 16px;
        text-transform: uppercase;
        padding: 0.8em 15px;
        background-image: url('/media/images/icons/plus-icon.png');
        background-repeat: no-repeat;
        background-position: 97% center;
        letter-spacing: 1.4px;
        font-weight: bold;
        @media #{$medium-up} {
          background: none;
          font-size: 26px;
          margin-bottom: 0.4em;
          width: 46%;
          text-transform: none;
          padding: 0;
          letter-spacing: normal;
          font-weight: normal;
          font-family: $base-font-family;
          @if $cr19 == true {
            font-family: $base-bolder-font-family;
          }
        }
        @if $cr19 == true {
          @include text-title--med;
          font-size: 15px;
          font-weight: normal;
          font-family: $base-bolder-font-family;
          padding: 15px 0;
          color: $color-black;
          text-transform: inherit;
          background-position: #{$rdirection};
          @media #{$cr19-xlarge-up} {
            margin-bottom: 25px;
            font-size: 28px;
            line-height: get-line-height(28px, 32px);
          }
        }
      }
      .product-full__accordion__copy {
        padding: 0 15px 15px 15px;
        display: none;
        .atff_title {
          font-size: 13px;
          font-weight: bold;
          font-family: 'HelveticaNeueLTStd65Medium';
          margin: 15px 0 0 0;
        }
        @if $cr19 == true {
          padding: 0;
          @include text-body-text--no-xl;
          @media #{$cr19-medium-up} {
            padding: 0 15px 15px;
          }
        }
        .atff_copy {
          margin-top: 0;
        }
        @media #{$medium-up} {
          display: block;
          padding: 0;
        }
        @if $cr19 == true {
          p {
            @include text-body-text--no-xl;
            color: $color-black;
            margin: 0 0 25px;
          }
          .atff_title,
          strong {
            @include text-title--small;
            color: $color-black;
            font-weight: normal;
            @media #{$cr19-xlarge-up} {
              font-size: 17px;
              line-height: get-line-height(17px, 24px);
            }
          }
        }
      }
      .product-full__ingredients-sub__copy,
      .product-full__ingredients-read-more-sub__copy {
        display: none;
        @media #{$medium-up} {
          padding-bottom: 30px;
          @if $cr19 == true {
            padding: 12px 0 20px;
            p {
              margin: 0;
            }
          }
        }
      }
      .product-full__ingredients-sub,
      .product-full__ingredients-read-more-sub {
        display: none;
        @media #{$medium-up} {
          display: block;
          padding: 30px 0 20px 0;
        }
        @if $cr19 == true {
          @media #{$cr19-medium-up} {
            padding-bottom: 30px;
          }
        }
        &.active {
          .product-full__ingredients-sub__copy,
          .product-full__ingredients-read-more-sub__copy {
            display: block;
          }
        }
      }
      .product-full__ingredients-iln {
        padding-top: 10px;
      }
      .product-full__ingredients-read-more-sub {
        display: block;
        &.mobile-hidden {
          display: none;
        }
        @media #{$medium-up} {
          padding: 0;
          &.mobile-hidden {
            display: block;
          }
        }
      }
    }
    .product-full__accordion__panel.active {
      .product-full__accordion__copy {
        display: block;
        @if $cr19 == true {
          margin-bottom: 20px;
          @media #{$cr19-medium-up} {
            margin-bottom: 0;
          }
        }
      }
      .product-full__accordion__title {
        background-image: url('/media/images/icons/minus-icon.png');
        @media #{$medium-up} {
          background-image: none;
        }
      }
    }
    .product-full_accordion__image {
      display: none;
      @media #{$medium-up} {
        display: block;
      }
    }
    .product-full__ingredients-sub__title,
    .product-full__ingredients-read-more-sub__title {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 5px;
      border-bottom: 1px solid $color-cl-grey;
      &:after {
        content: url('/media/images/icons/caret-down.png');
        padding-left: 5px;
      }
      @if $cr19 == true {
        @include text-title--small;
        &:after {
          float: #{$rdirection};
          content: url('/media/images/icons/plus-icon.png');
        }
        border-radius: 0;
        border: 1px solid $cr19-border-light;
        border-#{$ldirection}: none;
        border-#{$rdirection}: none;
        padding: 20px 0;
        color: $color-black;
        width: 100%;
        @media #{$cr19-medium-up} {
          font-size: 17px;
          line-height: get-line-height(17px, 24px);
        }
      }
    }
    @if $cr19 == true {
      .product-full__ingredients-sub.active,
      .product-full__ingredients-read-more-sub.active {
        .product-full__ingredients-sub__title,
        .product-full__ingredients-read-more-sub__title {
          border-bottom: none;
          &:after {
            content: url('/media/images/icons/minus-icon.png');
          }
        }
        .product-full__ingredients-sub__copy,
        .product-full__ingredients-read-more-sub__copy {
          border-bottom: 1px solid $cr19-border-light;
        }
      }
    }
    .product-full__ingredients-read-more-sub__title {
      margin: 10px 0;
      @media #{$medium-up} {
        margin: 0;
      }
    }
  }
  .product-full__accordion:last-child .product-full__accordion__panel {
    border-bottom: 1px solid $color-lighter-grey;
    @if $cr19 == true {
      border-bottom: 1px solid $cr19-border-light;
    }
    @media #{$medium-up} {
      border-bottom: none;
    }
  }
  .product-full__video {
    height: 100%;
    position: relative;
    cursor: pointer;
    .product-full__video--poster {
      position: relative;
    }
    .product-full__video__reveal {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
    .product-full__video--poster-image {
      width: 100%;
    }
    .icon--play {
      width: 15%;
      height: 15%;
      position: absolute;
      top: 10%;
      right: 2%;
      background-image: url('/media/images/icons/video_play.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .youtube-video {
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }
  .product-full__cross-sell {
    display: none;
    @media #{$medium-up} {
      display: block;
    }
    padding: 0 15px;
    @if $cr19 == true {
      padding: 30px 0 0;
      margin-top: 60px;
      border-top: 1px solid $cr19-border-light;
    }
    &__title {
      color: $color-black;
    }
    &__item {
      width: 100%;
      vertical-align: top;
      padding: 0 10px;
      @media #{$medium-up} {
        width: 18%;
        display: inline-block;
      }
      &__image {
        width: 100%;
      }
      &__title {
        color: $color-black;
        margin: 0;
      }
      &__price {
        margin-bottom: 15px;
      }
    }
  }
  //BV styling updates
  .BVPullquoteWidgetContainer {
    border-bottom: none;
  }
}

.product-full__loyalty-price {
  display: none;
  &.active {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    @media #{$medium-up} {
      font-size: 20px;
    }
    + .product-full__footer .product-full__cta {
      border: none;
      margin: 0;
      padding: 0;
      flex-flow: wrap;
      max-width: 400px;
      font-size: 16px;
      @media #{$medium-up} {
        font-size: 20px;
      }
      .product-full__original-price--label,
      .price {
        font-size: 16px;
        display: block;
        @media #{$medium-up} {
          font-size: 20px;
        }
      }
      .product-full__original-price--label {
        width: 50%;
        text-align: $rdirection;
      }
      .product-full__add-to-bag {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
  &--container {
    display: flex;
    max-width: 400px;
  }
  &--value {
    width: 40%;
  }
  &--label {
    width: 60%;
    text-align: $rdirection;
  }
  &--link {
    color: $color-black;
    font-weight: 400;
  }
  &--header {
    margin-bottom: 10px;
  }
}

.product-full__original-price--label {
  display: none;
}
