.cl-mpp-product-layout-v1 {
  .hidden {
    display: none !important;
  }
  .mpp-wrapper {
    position: relative;
    max-width: 1000px;
    margin: 0px auto;
    padding: 0px 20px;
    h3 {
      color: $color-black;
      font-size: 22px;
      letter-spacing: -0.5px;
      line-height: 1;
      clear: both;
      a {
        @include cl-standard-link($color-cl-green, false, $color-cl-green-2-darker);
        text-decoration: underline !important;
      }
    }
  }
  .mpp-grid {
    margin-bottom: 60px;
    a {
      text-decoration: none;
      h3 {
        @include cl-standard-link($color-text-black, false, $color-cl-green-2-darker);
      }
    }
    .product-subline,
    .product-subheading {
      a {
        @include cl-standard-link($color-text-black, false, $color-cl-green-2-darker);
      }
    }
    #mpp-product-grid {
      &.view-small {
        #quickshop {
          top: 2px;
        }
        .badge {
          &.badge--medium {
            display: none;
          }
        }
      }
      &.view-small-2022 {
        #quickshop {
          top: 2px;
        }
        .badge {
          &.badge--medium {
            display: none;
          }
        }
      }
      &.view-large {
        #quickshop {
          top: 1px;
        }
        .mpp-product-hover-view {
          .badge.badge--medium {
            top: 10px;
            left: 1px;
          }
        }
        .badge {
          &.badge--medium {
            display: inline-block;
          }
          &.badge--small {
            display: none;
          }
        }
      }
    }
    li.mpp-box {
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 0;
      .product-info {
        position: relative;
        top: -10px;
        margin-bottom: -20px;
      }
      .product-info-2022 {
        position: relative;
        margin-top: 10px;
        min-height: 196px;
        font-size: 17px;
      }
      .product-info.price2 {
        .strike-thru-price {
          color: $color-shade-grey;
          display: inline;
          font-size: 13px;
          line-height: 1.2;
          text-decoration: line-through;
          & ~ .strike-thru-price {
            display: none; // only show the first one
          }
        }
        .product-price,
        .price {
          color: $color-discount-price;
          display: block;
          font-size: 17px;
        }
      }
      .product-image {
        margin: 0 auto;
        display: none;
        width: 302px;
        height: 349px;
        &.threeup {
          display: block;
          margin-#{$ldirection}: -1px;
        }
        &.lazyload {
          visibility: hidden;
        }
      }
      p {
        margin: 0;
        font-family: $helvetica-light-font-family;
        font-size: 15px;
        line-height: 17px;
        &.type {
          color: $color-cl-soft-black;
        }
        &.price {
          color: $color-darker-grey;
          font-family: $helvetica-regular-font-family;
          font-size: 13px;
          line-height: 1.2;
        }
      }
      .reviews {
        @include swap_direction(padding, 3px 0px 7px 53px);
        font-size: 11px;
        color: $color-cl-green;
        font-family: $helvetica-regular-font-family;
        .lazyload {
          visibility: hidden;
        }
      }
      .badge {
        @include border-radius(50%);
        position: absolute;
        top: 30px;
        #{$ldirection}: 45px;
        display: table;
        width: 80px;
        height: 80px;
        text-align: center;
        color: $color-white;
        z-index: 1;
        padding: 0;
        line-height: 16px;
        font-family: $helvetica-light-font-family;
        vertical-align: middle;
        margin: 0;
        font-size: 14px;
        text-transform: uppercase;
        &.badge_1,
        &.badge_2,
        &.badge_3,
        &.badge_4,
        &.badge_5,
        &.badge_17,
        &.badge_92,
        &.badge_94,
        &.badge_96,
        &.badge_97 {
          background: $color-hot-pink;
        }
        /* for longer badge names */
        &.badge_96 {
          font-size: 12px;
        }
        &.badge_30 {
          background: $color-cl-green-2;
          @if $cr19 == true {
            background-color: $cr19-bg-black;
            color: $cr19-text-white;
          }
        }
        &.badge_101 {
          background: $color-cl-purple-2;
        }
        &.badge--medium,
        &.badge--small {
          z-index: 0;
        }
        html.ie7 & {
          text-align: center;
        }
        .badge-inner {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          html.ie7 & {
            position: relative;
            margin-top: expression(
              offsetHeight < parentNode.offsetHeight ?
                parseInt((parentNode.offsetHeight - offsetHeight) / 2) + 'px': '0'
            );
            clear: none;
          }
        }
        &.badge--medium,
        &.badge--large,
        &.badge--small {
          top: 0px;
          #{$ldirection}: 0px;
        }
      }
      .btn-quickview {
        cursor: pointer;
        text-transform: uppercase;
        font-weight: normal;
        background-color: $color-white;
        border: solid 1px $color-dusty-gray;
        color: $color-dusty-gray;
        margin: 0;
        visibility: hidden;
        height: 0;
        padding: 0;
      }
      .btn-shopnow {
        @include swap_direction(margin, 0px 0px 5px 50px);
        cursor: pointer;
        visibility: hidden;
      }
      .mpp-product {
        .btn-quickview {
          display: none;
        }
      }
      &:hover .mpp-product-hover-view {
        display: block;
      }
      .mpp-product-hover-view {
        display: none;
        position: absolute;
        top: -11px;
        padding-top: 10px;
        #{$ldirection}: -1px;
        width: 302px;
        padding-bottom: 20px;
        background: $color-white;
        z-index: 10;
        border: 1px solid $color-black;
        min-height: 490px;
        .btn-favorite {
          position: absolute;
          top: 7px;
          #{$rdirection}: 7px;
          height: 25px;
          width: 25px;
          background: url($img_base_path + '/btn-favorite-sprite.png') no-repeat 0px 0px;
          &:hover,
          &.active {
            background-position: 0px -24px;
          }
        }
        .btn-quickview {
          margin: 0 0 10px;
          height: auto;
          padding: 4px 10px 3px;
          visibility: visible;
          position: absolute;
          top: 180px;
          #{$ldirection}: 64px;
          &:hover {
            color: $color-cl-green-2;
            border-color: $color-cl-green-2;
            @if $cr19 == true {
              background-color: $color-white;
              border-color: $cr19-text;
              color: $cr19-text;
            }
          }
        }
        .btn-shopnow {
          visibility: visible;
          margin-top: 5px;
        }
        .compare-container {
          position: absolute;
          top: 10px;
          #{$ldirection}: 10px;
          display: block;
          color: $color-black;
          text-transform: uppercase;
          font-size: 12px;
          z-index: 100;
          input {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            height: 20px;
            width: 20px;
            border: solid 1px $color-darker-grey;
            margin-#{$rdirection}: 5px;
          }
        }
        .badge {
          top: 40px;
        }
        .badge.badge--medium,
        .badge.badge--large,
        .badge.badge--small {
          top: 0px;
          #{$ldirection}: 0px;
        }
      }
      .caret {
        background: url('/media/export/images/qs-caret.png') no-repeat scroll 50% 0 $color-black;
        display: block;
        height: 12px;
        #{$ldirection}: 0;
        padding: 0;
        position: absolute;
        top: 329px;
        width: 182px;
        z-index: 9000;
      }
    }
    ul {
      padding: 0px;
      max-width: 1000px;
      margin: 0px auto;

      &.view-small-2022 {
        padding: 72px;
        max-width: 1920px;
        position: relative;
        overflow-y: visible;
        margin: auto;
      }

      &.view-large li.mpp-box {
        width: 303px;
        margin-#{$rdirection}: 25px;
        min-height: 490px;
        position: relative;
        &:nth-child(3n) {
          margin-#{$rdirection}: 0px !important;
        }
        p {
          padding: 0px 53px;
          font-family: $helvetica-light-font-family;
          font-size: 15px;
          line-height: 1.2;
          word-spacing: -0.3mm;
        }
        h3 {
          margin: 0;
          padding: 0 53px;
          font-family: $helvetica-light-font-family;
          font-size: 15px;
          line-height: 1.2;
          word-spacing: -0.3mm;
          width: 302px;
        }
        .btn-quickview {
          top: 300px;
          #{$ldirection}: 105px;
        }
        .caret {
          top: 480px;
          #{$ldirection}: 60px;
        }
      }
      &.view-small-2022 li.mpp-box {
        width: 25%;
        padding: 10px;
        position: relative;
        &:nth-child(5n) {
          margin-#{$rdirection}: 0px !important;
        }
        .mpp-product {
          background: #fafafa;
        }
        .product-image {
          height: auto;
          display: block;
          width: 100%;
          margin-#{$ldirection}: -1px;
          &.threeup {
            display: none;
          }
        }
        h3,
        .product-subline,
        .product-subline a,
        .product-subheading,
        .product-subheading a {
          margin: 0;
          font-family: $helvetica-regular-font-family;
          font-size: 17px;
          line-height: 1.2;
          width: 90%;
          margin-bottom: 12px;
        }
        .reviews {
          padding-#{$ldirection}: 0px;
        }
        .btn-shopnow {
          margin-#{$ldirection}: 0px;
        }
        .badge {
          top: 35px;
          #{$ldirection}: 28px;
          width: 60px;
          height: 60px;
          line-height: 11px;
          font-size: 11px;
        }
        .badge.badge--medium,
        .badge.badge--large,
        .badge.badge--small {
          top: 0px;
          #{$ldirection}: 0px;
        }
        .mpp-product-hover-view {
          width: 100%;
          border: none;
          min-height: 0;
        }
        .mpp-product-hover-view .badge {
          top: 45px;
          #{$ldirection}: 38px;
        }
        .mpp-product-hover-view .badge.badge--medium,
        .mpp-product-hover-view .badge.badge--large,
        .mpp-product-hover-view .badge.badge--small {
          top: 10px;
          #{$ldirection}: 10px;
        }
      }
      &.view-small li.mpp-box {
        width: 182px;
        margin-#{$rdirection}: 12px;
        height: 338px;
        &:nth-child(5n) {
          margin-#{$rdirection}: 0px !important;
        }
        .product-image {
          width: 181px;
          height: 209px;
          display: block;
          margin-#{$ldirection}: -1px;
          &.threeup {
            display: none;
          }
        }
        h3,
        .product-subline,
        .product-subline a,
        .product-subheading,
        .product-subheading a {
          margin: 0;
          font-family: $helvetica-regular-font-family;
          font-size: 13px;
          line-height: 1.2;
          width: 90%;
        }
        .reviews {
          padding-#{$ldirection}: 0px;
        }
        .btn-shopnow {
          margin-#{$ldirection}: 0px;
        }
        .mpp-product-hover-view {
          #{$ldirection}: -11px;
          width: 180px;
          padding-#{$ldirection}: 10px;
          padding-#{$rdirection}: 10px;
          min-height: 340px;
        }
        .badge {
          top: 35px;
          #{$ldirection}: 28px;
          width: 60px;
          height: 60px;
          line-height: 11px;
          font-size: 11px;
        }
        .badge.badge--medium,
        .badge.badge--large,
        .badge.badge--small {
          top: 0px;
          #{$ldirection}: 0px;
        }
        .mpp-product-hover-view .badge {
          top: 45px;
          #{$ldirection}: 38px;
        }
        .mpp-product-hover-view .badge.badge--medium,
        .mpp-product-hover-view .badge.badge--large,
        .mpp-product-hover-view .badge.badge--small {
          top: 10px;
          #{$ldirection}: 10px;
        }
      }
    }
  }
  .mpp-filter {
    @include swap_direction(padding, 23px 0px 0px 0px);
    @include swap_direction(margin, 0 0 15px 0);
    overflow: hidden;
    border-bottom: 1px solid $color-grey;
    background: url('/sites/clinique/themes/cl_base/img/mpp/mpp-filter-bkg.png') repeat-x bottom
      $color-white;
    h1 {
      margin: 0px;
      float: #{$ldirection};
      font-family: $helvetica-light-font-family;
      font-size: 36px;
      line-height: normal;
      letter-spacing: -0.3mm;
      white-space: nowrap;
      .item-count {
        @include swap_direction(padding, 0 0 5px 0);
        font-size: 22px;
        letter-spacing: normal;
        vertical-align: middle;
      }
    }
    .btn-filter-expand-collapse {
      &.large {
        display: none;
        width: auto;
        margin-bottom: 20px;
        font-size: 17px;
        height: 37px;
        line-height: 41px;
        padding: 0 15px;
        .expanded-arrow {
          display: none;
        }
        &:hover {
          background-color: $color-cl-green-2;
        }
        &.expanded {
          &,
          &:hover {
            background-color: $color-btn-hover;
          }
          .unexpanded-arrow {
            display: none;
          }
          .expanded-arrow {
            display: inline;
          }
        }
      }
      &.small {
        float: #{$rdirection};
        background: url('/media/images/produkter/mpp-filter-expand-collapse-sprite.png') no-repeat;
        display: block;
        height: 20px;
        position: absolute;
        #{$rdirection}: 28px;
        top: 15px;
        width: 22px;
        cursor: pointer;
        &.expanded {
          background-position: 0 -20px;
        }
      }
    }
    .mpp-filter-subheaders {
      display: block;
      clear: both;
      padding-top: 30px;
      h2 {
        margin: 0 0 15px;
        font-family: $helvetica-light-font-family;
        font-size: 22px;
        letter-spacing: -0.1mm;
        line-height: 1.1;
        color: $color-cl-darker-grey;
      }
    }
    .mpp-filter-column2 h2 {
      margin-#{$ldirection}: 25px;
    }
    .mpp-filter-column1 {
      float: #{$ldirection};
      width: 67%;
      ul {
        position: relative;
        #{$ldirection}: -10px;
      }
    }
    .mpp-filter-column2 {
      float: #{$rdirection};
      width: 30%;
      position: relative;
      border-#{$ldirection}: solid 1px $color-shade-grey;
    }
    &.fixed-width-items-only {
      & .mpp-filter-column1 {
        width: 65%;
        font-size: 15px;
        line-height: 1.2;
        word-spacing: -0.3mm;
      }
      & .mpp-filter-column2 {
        padding-#{$ldirection}: 15px;
        width: 34%;
      }
      .mpp-filter-column2 h2 {
        margin-#{$ldirection}: 10px;
      }
    }
    .mpp-filter-column1 ul,
    .mpp-filter-column2 ul {
      padding-#{$ldirection}: 0px;
    }
    .mpp-filter-column1.narrow ul {
      width: 67%;
    }
    .mpp-filter-content {
      .mpp-filter-column1,
      .mpp-filter-column2 {
        margin-bottom: 10px;
      }
      li.filter-item {
        &.group_2,
        &.group_3,
        &.group_4 {
          width: 74px;
          margin-#{$ldirection}: 0;
          margin-#{$rdirection}: 0;
          display: inline-block;
          a {
            text-align: center;
            background: no-repeat center 25px;
            min-height: 120px;
            padding: 85px 5px 0;
            font-family: $helvetica-regular-font-family;
            font-size: 13px;
            line-height: 1.2;
          }
        }
        &.naturals_nudes a {
          background-image: url($img_base_path + '/mpp/naturals-nudes.png');
        }
        &.naturals_browns a {
          background-image: url($img_base_path + '/mpp/naturals-browns.png');
        }
        &.nudes a {
          background-image: url($img_base_path + '/mpp/nudes.png');
        }
        &.pinks a {
          background-image: url($img_base_path + '/mpp/pinks.png');
        }
        &.violets a {
          background-image: url($img_base_path + '/mpp/violets.png');
        }
        &.tawny_corals a {
          background-image: url($img_base_path + '/mpp/tawny-corals.png');
        }
        &.goldens_to_greens a {
          background-image: url($img_base_path + '/mpp/goldens-to-greens.png');
        }
        &.violets_blues a {
          background-image: url($img_base_path + '/mpp/violets-berries.png');
        }
        &.very_fair a {
          background-image: url($img_base_path + '/mpp/very-fair.png');
        }
        &.moderately_fair a {
          background-image: url($img_base_path + '/mpp/moderately-fair.png');
        }
        &.medium a {
          background-image: url($img_base_path + '/mpp/medium.png');
        }
        &.deep a {
          background-image: url($img_base_path + '/mpp/deep.png');
        }
        &.cream a {
          background-image: url($img_base_path + '/mpp/cream_transparent.png');
        }
        &.powder a {
          background-image: url($img_base_path + '/mpp/powder_transparent.png');
        }
        &.liquid a {
          background-image: url($img_base_path + '/mpp/liquid_transparent.png');
        }
        &.pencil a {
          background-image: url($img_base_path + '/mpp/pencil_transparent.png');
        }
        &.group_4.cream a {
          background-image: url($img_base_path + '/mpp/cream-bw_transparent.png');
        }
        &.group_4.powder a {
          background-image: url($img_base_path + '/mpp/powder-bw_transparent.png');
        }
        &.group_4.liquid a {
          background-image: url($img_base_path + '/mpp/liquid-bw_transparent.png');
        }
      }
      .mpp-filter-column2 li.filter-item {
        &.group_2,
        &.group_3,
        &.group_4 {
          a {
            margin-#{$ldirection}: 0;
          }
        }
      }
    }
    .mpp-filter-column1 ul li,
    .mpp-filter-column2 ul li {
      @include swap_direction(margin, 0 6px 2px 0);
      display: inline-block;
      width: 30%;
      vertical-align: top;
      padding: 0;
      position: relative;
    }
    .mpp-filter-column1.narrow ul li {
      width: 45%;
    }
    .mpp-filter-column1 ul li a {
      letter-spacing: -0.01em;
      font-size: 13px;
      font-family: $helvetica-regular-font-family;
      min-height: 27px;
      color: $color-black;
      display: block;
      padding-top: 6px;
      padding-#{$rdirection}: 5px;
      padding-bottom: 0;
      vertical-align: baseline;
      width: auto;
      text-align: #{$ldirection};
    }
    .mpp-filter-column1 ul li a .close-filter,
    .mpp-filter-column2 ul li a .close-filter {
      width: 12px;
      height: 11px;
      background: url('/media/images/produkter/remove-filter-x.png') no-repeat 0 0 $color-white;
      display: none;
      font-size: 12px;
      text-align: center;
      line-height: 13px;
      font-style: normal;
      position: absolute;
      padding: 0;
      top: 8px;
      #{$rdirection}: 9px;
      color: $color-black;
      text-indent: -9999px;
      overflow: hidden;
    }
    .mpp-filter-column1 ul li a:hover,
    .mpp-filter-column2 ul li a:hover {
      color: $color-cl-green-2;
      text-decoration: none;
    }
    .mpp-filter-column1 ul li a.active,
    .mpp-filter-column2 ul li a.active {
      border: 1px solid $color-cl-grey;
      color: $color-black;
      border-radius: 8px;
      -webkit-box-shadow: 0 0 3px 3px $color-gray-transparent;
      box-shadow: 0 0 3px 3px $color-gray-transparent;
      background-color: $color-white;
    }
    .mpp-filter-column1 ul li a.active span,
    .mpp-filter-column2 ul li a.active span {
      display: block;
    }
    .mpp-filter-column1 ul li.inactive,
    .mpp-filter-column2 ul li.inactive {
      opacity: 0.2;
    }
    .mpp-filter-column1 ul li.inactive b {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 0;
      z-index: 11;
      position: absolute;
      top: 0;
    }
    .mpp-filter-column2 ul li {
      &.inactive b {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 0;
        z-index: 11;
        position: absolute;
        top: 0;
      }
      width: auto;
      float: none;
      display: block;
      margin-#{$rdirection}: 0;
      a {
        letter-spacing: -0.01em;
        font-size: 13px;
        font-family: $helvetica-regular-font-family;
        min-height: 27px;
        color: $color-black;
        display: block;
        padding-top: 6px;
        padding-#{$rdirection}: 5px;
        padding-bottom: 0;
        vertical-align: baseline;
        width: auto;
        text-align: #{$ldirection};
        margin-#{$ldirection}: 15px;
        padding: 3px 0;
        .skin-type-icon {
          @include swap_direction(margin, 0 10px 0 10px);
          display: inline-block;
          text-align: center;
          width: 17px;
          height: 17px;
          line-height: 18px;
          color: $color-white;
          font-size: 12px;
          font-family: $helvetica-regular-font-family;
          border-radius: 50%;
          background: $color-darker-grey;
        }
        &.active .skin-type-icon {
          display: inline-block;
        }
      }
      &.type-I a.active .skin-type-icon {
        background: $color-monte-carlo;
      }
      &.type-II a.active .skin-type-icon {
        background: $color-melanie;
      }
      &.type-III a.active .skin-type-icon {
        background: $color-wewak;
      }
      &.type-IV a.active .skin-type-icon {
        background: $color-aqua-island;
      }
    }
  }
  .mpp-view-options {
    margin: 0;
    min-height: 25px;
    h2 {
      float: #{$ldirection};
      margin: 0;
      color: $color-cl-darker-grey;
      width: 200px;
      font-size: 15px;
      line-height: 1.2;
      word-spacing: -0.3mm;
    }
    .mpp-view-toggle {
      @include swap_direction(margin, 0 30px 0 0);
      float: #{$rdirection};
      color: $color-black;
      font-size: 14px;
      font-family: $helvetica-light-font-family;
      a {
        &.view-large,
        &.view-small,
        &.view-small-2022 {
          @include swap_direction(margin, 0px 0px 0px 5px);
          background: url('/media/export/images/toggle-view-sprite.png') no-repeat 0 0;
          /* mpp-toggle-view-sprite.png */
          width: 16px;
          height: 16px;
          display: block;
          float: #{$rdirection};
          border: 0;
        }
        &.view-large {
          background-position: 0px 0px;
          &.active {
            background-position: 0px -16px;
          }
        }
        &.view-small,
        &.view-small-2022 {
          background-position: -16px 0px;
          &.active {
            background-position: -16px -16px;
          }
        }
      }
      span {
        float: #{$ldirection};
        font-family: $helvetica-regular-font-family;
        font-size: 13px;
        line-height: 1.2;
        margin-#{$rdirection}: 5px;
      }
    }
    .mpp-sort-by {
      color: $color-black;
      float: #{$rdirection};
      position: relative;
      padding: 0px;
      margin: 0px;
      font-family: $helvetica-regular-font-family;
      font-size: 13px;
      line-height: 1.2;
      li {
        @include swap_direction(padding, 0 0 0 8px);
        display: inline;
        a {
          color: $color-darker-grey;
          font-family: $helvetica-regular-font-family;
          font-size: 13px;
          line-height: 1.2;
          &.active {
            color: $color-cl-soft-black;
            &:hover {
              color: inherit;
              cursor: inherit;
            }
          }
        }
      }
      .sort-more-trigger {
        background: url('mpp-sort-more-down.png') no-repeat 100% 2px;
        padding-#{$rdirection}: 13px;
        display: inline-block;
      }
      .sort-more-dropdown {
        &.hidden {
          display: none;
        }
        position: absolute;
        #{$rdirection}: -18px;
        top: 20px;
        background: $color-white;
        border-bottom: solid 1px $color-grey;
        z-index: 2;
        padding: 5px 0;
        margin: 0px;
        li {
          display: block;
          margin-bottom: 8px;
        }
      }
    }
  }
}
/* Product Grid Section */

/* 3-Up Design */

/* 5-Up Design */

/* Filter Section */

/* Sort By/View Options Section */

/* iPad Styles */
@media screen and (max-width: 768px) {
  .cl-mpp-product-layout-v1 {
    .mpp-grid {
      #mpp-product-grid {
        width: 600px;
        .btn-quickview {
          position: absolute;
          visibility: visible;
          display: block;
        }
        &.view-small,
        &.view-small-2022 {
          .product-image {
            margin-bottom: 10px;
          }
          .btn-quickview {
            #{$ldirection}: 44px;
            top: 190px;
          }
        }
        &.view-large {
          width: 660px;
          .btn-quickview {
            #{$ldirection}: 105px;
            top: 315px;
          }
        }
        li.mpp-box {
          &:hover .mpp-product-hover-view {
            display: none;
          }
          .btn-shopnow {
            visibility: visible;
          }
        }
        #quickshop {
          width: inherit;
        }
      }
    }
    .mpp-filter {
      max-height: 404px;
      .btn-filter-expand-collapse {
        &.small {
          display: none;
        }
        &.large {
          display: block;
          clear: both;
        }
      }
      .subheaders h2 {
        font-size: 17px;
        line-height: 22px;
      }
    }
    .cl-mpp-product-layout-v1 {
      .mpp-grid li.mpp-box {
        .badge {
          &.badge_101 {
            background: $color-cl-purple-2;
          }
        }
      }
    }
  }
}
