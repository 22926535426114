.section-clinicalreality {
  .nav-promo {
    @include start-breakpoint($medium-min) {
      display: none;
    }
  }
  .main {
    clear: both;
  }
}

.clinique-reality-overlay {
  iframe {
    height: 380px;
  }
}
