.device-mobile {
  a {
    input {
      letter-spacing: normal;
      line-height: 2;
      @include swap_direction(padding, 2px 10px 0px);
    }
    &.edit-address,
    &.delete-address {
      input {
        &.small-button {
          @include swap_direction(padding, 2px 10px 0px);
        }
      }
    }
  }
  .store-locator-mobile-v1 {
    .map_container {
      width: 100%;
      .map_canvas {
        width: 100%;
      }
    }
  }
  #top {
    #header {
      .btn_navigation {
        position: relative;
        left: 0;
      }
      .block-template-search-mobile-v1 {
        position: relative;
        left: 0;
      }
      .block-template-site-logo-responsive-v1 {
        width: 47% !important;
        .site-logo-responsive-v1 {
          padding: 0 !important;
        }
      }
    }
  }
}

.popup-offer-v1 {
  .outer-wrap {
    .signup-forms {
      .form-submit-wrap {
        margin: 0px;
      }
    }
  }
}

.device-mobile {
  #footer {
    .footer-bottom {
      .field-mobile-menu {
        ul.menu > li.item-3,
        ul.menu > li.item-5 {
          border-left: 1px solid #231f20;
          padding-left: 10px;
        }
      }
    }
  }
}

#password_reset {
  div.label {
    float: left;
    width: 100%;
  }
  .form-item-reset {
    float: left;
    width: 50%;
    input {
      width: 100%;
    }
  }
}

.spp_reviews {
  .BVSearchResults {
    .BVValue {
      font-weight: normal;
    }
  }
}

.show-popup-offer {
  img {
    height: 0px;
  }
}
